import React from "react";
import "./tableOfContents.css";

const ListItem = ({ entry }) => {
  return (
    <li>
      <a href={entry.url}>{entry.title}</a>
      {"items" in entry ? <List content={entry.items}></List> : ""}
    </li>
  );
};

const List = ({ content }) => {
  return (
    <ol className="tableOfContents">
      {content.map((entry, index) => (
        <ListItem key={entry.url} entry={entry}></ListItem>
      ))}
    </ol>
  );
};

const TableOfContents = ({ content }) => {
  if (
    typeof content !== "undefined" &&
    "items" in content &&
    "items" in content.items[0]
  ) {
    return <List content={content.items[0].items}></List>;
  }
  return "";
};

export default TableOfContents;
